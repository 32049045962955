<template>
    <div class="conclusion-element">
        <div :style="type == 'detail' ? '' : 'margin-top: 15px;'"> 
            <div class="pull-left" v-if="type == 'popup'">
                <strong style="color:orange;">{{ $t("Rationale and comments") }} </strong>
            </div>
            <div class="pull-right">
                <p v-if="hta_date" style="margin-bottom:0"><strong>{{ $t("overview.HTA date").replace('overview.', '') }} : </strong> <span v-html="hta_date"></span></p>
                <p v-if="hta_decision" style="margin-bottom:0"><strong>{{ $t("overview.HTA decision").replace('overview.', '') }} :</strong> <span v-html="hta_decision"></span></p>
                <p v-if="rembursement_date" style="margin-bottom:0"><strong>{{ $t("overview.Reimbursement date").replace('overview.', '') }} :</strong> <span v-html="rembursement_date"></span> </p>
                <p v-if="rembursement_process" style="margin-bottom:0"><strong>{{ $t("overview.Reimbursement status").replace('overview.', '') }} :</strong> <span v-html="rembursement_process"></span> </p>
                <p v-if="decision_date" style="margin-bottom:0"><strong>{{ $t("overview.Decision date").replace('overview.', '') }} :</strong> <span v-html="decision_date"></span> </p>
                <p v-if="decision" style="margin-bottom:0"><strong>{{ $t("overview.Decision").replace('overview.', '') }} :</strong> <span v-html="decision"></span> </p>
            </div>
        </div>

        <table class="comparateur2 text-left prismheor-table-th-not-white" v-if="item['prioritis_hta_index']">       
            <thead>
                <tr>
                    <th width="25%" style="vertical-align: middle;"> {{$t('prioritis_hta_index')}} </th>
                    <td width="75%" style="border-left: 1px solid #e6e9ee;">{{$t('conclusion.'+item['prioritis_hta_index']).replace('conclusion.', '')}}</td>
                </tr> 
            </thead>
        </table>

        <table class="comparateur2 text-left prismheor-table-th-not-white" v-if="condition_target_population">       
            <thead>
                <tr>
                    <th width="25%" style="vertical-align: middle;"> {{$t('target_population')}}</th>
                    <td width="75%" style="border-left: 1px solid #e6e9ee;">
                        <div class="yes-icon" v-if="item['check_target_population'] || check_yes"></div>
                        <span v-if="['en', 'de'].includes($i18n.locale) && item['target_population_'+$i18n.locale]" v-html="item['target_population_'+$i18n.locale]"></span>
                        <span v-else-if="item['target_population']" v-html="item['target_population'] ? item['target_population'] : '-'"></span>
                    </td>
                </tr>
            </thead>
        </table>  
        <table class="comparateur2 text-left prismheor-table-th-not-white" v-if="condition_prescription_and_delivery_conditions">       
            <thead>
                <tr>
                    <th width="25%" style="vertical-align: middle;"> {{$t('prescription_and_delivery_conditions')}} </th>
                    <td width="75%" style="border-left: 1px solid #e6e9ee;"> 
                        <div class="yes-icon" v-if="item['check_prescription_and_delivery_conditions'] || check_yes"></div>
                        <span v-if="['en', 'de'].includes($i18n.locale) && item['prescription_and_delivery_conditions_'+$i18n.locale]" v-html="item['prescription_and_delivery_conditions_'+$i18n.locale]"></span>
                        <span v-else-if="item['prescription_and_delivery_conditions']" v-html="item['prescription_and_delivery_conditions'] ? item['prescription_and_delivery_conditions'] : '-'"></span>
                    </td>
                </tr>
            </thead>
        </table>  

        
        <table class="comparateur2 text-left prismheor-table-th-not-white" v-if="condition_access_scheme">       
            <thead>
                <tr>
                    <th width="25%" style="vertical-align: middle;"> {{$t('access_scheme')}} </th>
                    <td width="75%" style="border-left: 1px solid #e6e9ee;">
                        <div class="yes-icon" v-if="item['check_access_scheme'] || check_yes"></div>
                        <span v-if="['en', 'de'].includes($i18n.locale) && item['access_scheme_'+$i18n.locale]"  v-html="item['access_scheme_'+$i18n.locale]"></span>
                        <span v-else-if="item['access_scheme']" v-html="item['access_scheme'] ? item['access_scheme'] : '-'"></span>
                    </td>
                </tr>
            </thead>
        </table>  

        <table class="comparateur2 text-left prismheor-table-th-not-white" v-if="condition_outcome_level">       
            <thead>
                <tr>
                    <th width="25%" style="vertical-align: middle;"> {{$t('outcome_level')}} </th>
                    <td width="75%" style="border-left: 1px solid #e6e9ee;">
                        <div class="yes-icon" v-if="item['check_outcome_level'] || check_yes"></div>
                        <span v-if="['en', 'de'].includes($i18n.locale) && item['outcome_level_'+$i18n.locale]" v-html="item['outcome_level_'+$i18n.locale]"></span>
                        <span v-else-if="item['outcome_level']" v-html="item['outcome_level'] ? item['outcome_level'] : '-'"></span>
                    </td>
                </tr>
            </thead>
        </table> 
    </div>
</template>

<script>
import { lowercase, color_rembursement, DDMMMYY } from '../../utils'
export default {
    name: "ConclusionElement",
    props: {
        item: {
            type: Object
        },
        type: {
            type: String,
            default: 'popup'
        }
    },
    data() {
        return {
            check : {},
            hta_date : null,
            hta_decision : null,
            rembursement_date : null,
            rembursement_process : null,
            decision_date : null,
            decision : null,
            rembursement_process_trs : '',
        }

    },
    created() {
        this.setVal()
    },
    computed: {
        check_yes(){
            return  this.item['prioritis_hta_index'] && this.item['target_population'] &&  this.item['prescription_and_delivery_conditions'] && 
                    this.item['access_scheme'] && this.item['outcome_level']
        },
        item_agency() {
            return this.lowercase(this.item.agency_model)
        },
        condition_target_population(){
            return  ['en', 'de'].includes(this.$i18n.locale)  && this.item['target_population_'+this.$i18n.locale] ||
                    this.item['target_population'] ||
                    this.check_yes || this.item['check_target_population']
        },
        condition_prescription_and_delivery_conditions(){
            return ['en', 'de'].includes(this.$i18n.locale) && this.item['prescription_and_delivery_conditions_'+this.$i18n.locale] ||
                    this.item['prescription_and_delivery_conditions'] ||
                    this.check_yes || this.item['check_prescription_and_delivery_conditions']
        },
        condition_access_scheme(){
            return ['en', 'de'].includes(this.$i18n.locale) && this.item['access_scheme_'+this.$i18n.locale] ||
                    this.item['access_scheme'] ||
                    this.check_yes || this.item['check_access_scheme']
        },
        condition_outcome_level(){
            return ['en', 'de'].includes(this.$i18n.locale) && this.item['outcome_level_'+this.$i18n.locale] ||
                    this.item['outcome_level'] ||
                    this.check_yes || this.item['check_outcome_level']
        }
        
    },
    methods : {
        lowercase,
        color_rembursement,
        DDMMMYY,
        setVal(){
            this.rembursement_process_trs = ''
            if(this.item.agency_model == 'Transparence' && this.item.transparence){
                if(this.item.transparence.not_t2a_exclusion_list && this.item.transparence.not_t2a_exclusion_list === 1){
                    this.rembursement_process_trs += ', '+ this.$t('rembursement_status.tc_Partial (not TA2 list)') 
                }
                if(this.item.reimbursement_decision == 'Yes' && this.item.transparence.trs_type_demandes === 'Prise en charge temporaire'){
                    this.rembursement_process_trs += ', '+ this.$t('rembursement_status.tc_Prise en charge temporaire')  
                }   
                if(this.item.transparence.trs_prise_en_charge_precoce){
                    this.rembursement_process_trs += ', '+ this.$t('Prise en charge précoce')
                }
            }
            
            switch (this.item.agency_type){
                case "HTA/Reimbursement": 
                    this.hta_date = this.DDMMMYY(this.item.hta_process_date)
                    this.hta_decision = this.item.hta_decision ? "<span class=\"" + this.color_rembursement(this.item.hta_decision) + "\" >" + this.$t("hta_decision." + this.item.hta_decision).replace('hta_decision.', '') +"</span>" : ''
                    this.rembursement_date = this.DDMMMYY(this.item.rembursement_process_date)
                    this.rembursement_process = this.item.reimbursement_decision ? "<span class=\"" + this.color_rembursement(this.item.reimbursement_decision) + "\" >" + this.$t("reimbursement_decision." + this.item.reimbursement_decision).replace('reimbursement_decision.', '') + this.rembursement_process_trs + "</span>" : ''
                break;
                case "Regulatory/Reimbursement": 
                    this.decision_date = this.DDMMMYY(this.item.decision_date_num)
                    this.decision = this.item.decision ? "<span class=\"" + this.color_rembursement(this.item.decision) + "\" >" + this.$t("conclusion." + this.item.decision).replace('conclusion.', '') + "</span>" : ''
                    this.rembursement_date = this.DDMMMYY(this.item.rembursement_process_date)
                    this.rembursement_process = this.item.reimbursement_decision ? "<span class=\"" + this.color_rembursement(this.item.reimbursement_decision) + "\" >" + this.$t("reimbursement_decision." + this.item.reimbursement_decision).replace('reimbursement_decision.', '') + this.rembursement_process_trs + "</span>" : ''
                break;
                case "Reimbursement": 
                    this.rembursement_date = this.DDMMMYY(this.item.rembursement_process_date)
                    this.rembursement_process = this.item.reimbursement_decision ? "<span class=\"" + this.color_rembursement(this.item.reimbursement_decision) + "\" >" + this.$t("reimbursement_decision." + this.item.reimbursement_decision).replace('reimbursement_decision.', '') + "</span>" : ''
                    // this.decision_date = this.DDMMMYY(this.item.decision_date_num)
                    // this.decision = this.item.decision ? "<span class=\"" + this.color_rembursement(this.item.decision) + "\" >" + this.$t("conclusion." + this.item.decision).replace('conclusion.', '') + "</span>" : ''
                break;
                case "HTA": 
                    this.hta_date = this.DDMMMYY(this.item.hta_process_date)
                    this.hta_decision = this.item.hta_decision ? "<span class=\"" + this.color_rembursement(this.item.hta_decision) + "\" >" + this.$t("hta_decision." + this.item.hta_decision).replace('hta_decision.', '') + "</span>" : ''
                break;
                case "Regulatory": 
                break;
                default:
                    //Other
                    this.decision_date = this.DDMMMYY(this.item.decision_date_num)
                    this.decision = this.item.decision ? "<span class=\"" + this.color_rembursement(this.item.decision) + "\" >" + this.$t("conclusion." + this.item.decision).replace('conclusion.', '') + "</span>" : ''
                break;

            }
        }
    },
    watch: { 
        item: function() {
            console.log(this.item)
            this.setVal()
        }
    },
}
</script>
