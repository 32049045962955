<template>
    <div v-if="longText && longText !== '-'" >
        <div :style="'overflow: hidden; height:'+height+'; text-align: '+textAlign+';'" >
            <div v-html="longText" ref="readmore"></div>
        </div>
        <div v-if="readmore_height > 37" class="text-center">
            <a class="readmore_click" v-if="height != 'auto'" @click="height = 'auto'">
                [...]
            </a>
            <a class="readmore_click" v-else @click="height = max_height">
                [ ^ ]
            </a>
        </div>
    </div>
    <div v-else style="text-align:center !important;"> - </div>
</template>
<script>
export default {
    name: "Readmore",     
    data(){
        return {
            max_height : "36px",
            height : "36px",            
            readmore_height : 0
        }
    }, 
    props: {
        longText : {
            type: String,
            default : ''
        },
        textAlign: {
            type: String,
            default: "left",
        }
    },
    mounted() {        
        if(this.$refs.readmore){
            this.readmore_height = this.$refs.readmore.offsetHeight
        }
    }
}
</script>

<style>
.readmore_click{
    color:#ff6300;  
    cursor: pointer;
}
</style>
