<template>
<teleport to="body">
<div class="modal fade" id="modal-hta-status" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-hta">
    <div class="modal-content modal-content-hta">       
      
        <div class="modal-body modal-dialog-hta">
            <div class="conclusion_head">

                <template v-if="type">
                    <img v-if="item.agency_model" :alt="item.agency_model" :src="require('@/assets/images/flag/new/'+item.icon_flags+'.svg')" width="20" height="20" />
                    <b><span style="vertical-align: middle; padding: 7px;">{{item.acronym}}</span></b>
                </template>
                <template v-else>
                    <img :alt="AgencyObj.agency_model" :src="require('@/assets/images/flag/new/'+AgencyObj.icon_flags+'.svg')" width="20" height="20" />
                    <b><span style="vertical-align: middle; padding: 7px;">{{AgencyObj.acronym}}</span></b>
                </template>
                <span style="vertical-align: middle; padding-right: 5px;">{{item.name}}</span>
                <span style="vertical-align: middle;" v-html="icon_link(item.drug_id, link_agency, class_='size20-cover')"></span>
            </div>
            <div class="custom-modal-header">
                <a class="icon-close-popup close-popup" data-bs-dismiss="modal" aria-label="Close" style="top: 10px; right: 10px;"></a>
            </div>

            <ConclusionElement :item="item"/>
            
            <template v-if="item.agency_model === 'Gba' && item.gba.gba_addition_benefit.length > 0">
                <table class="table table-2column ">
                    <tbody>
                        <tr>
                            <td style="width: 80%;">&nbsp;</td>
                            <td style="width: 20%;"><strong>Added beneﬁt (AB)</strong></td>
                        </tr>
                        <tr v-for="(item_gba_addition, key) in item.gba.gba_addition_benefit" :key="key">
                            <td>{{item_gba_addition.gba_addition_benefits_title}}</td>
                            <td>{{$t('gba_added_benefit_id_'+item_gba_addition.gba_addition_benefits_gba_added_benefit_id)}}</td>
                        </tr>
                    </tbody>
                </table>
            </template>

            <div class="fix_rationale_and_commentary" v-html="get_rationale_commentary(item)">        
            </div>

            <template v-if="item.agency_model === 'Transparence' && item.transparence && item.transparence.transparence_smr.length > 0">
                <table class="table table-2column ">
                    <tbody>
                        <tr>
                            <th style="width: 70%; border: 1px solid #e3dfdf !important;" >&nbsp;</th>
                            <th style="width: 10%; border: 1px solid #e3dfdf !important; text-align:center;"><strong>{{$t('asmr.ASMR_obtenue_short')}}</strong></th>
                            <th style="width: 10%; border: 1px solid #e3dfdf !important; text-align:center;"><strong>{{$t('asmr.ASMR_demande_short')}}</strong></th>
                            <th style="width: 10%; border: 1px solid #e3dfdf !important; text-align:center;"><strong>{{$t('SMR')}}</strong></th>
                        </tr>
                        <tr v-for="(item, key) in item.transparence.transparence_smr" :key="key">
                            <td>
                                <div style="text-align: justify;">
                                    {{ $i18n.locale !== 'fr' ? item.smr_title_en : item.smr_title}}
                                </div>
                            </td>
                            <td style="text-align:center;">{{item.asmr_obtenue ? $t("transparence.asmr."+item.asmr_obtenue) : "" }}</td>
                            <td style="text-align:center;">{{item.asmr_demande}}</td>
                            <td style="text-align:center;">{{item.smr_type ? $t("transparence.smr."+item.smr_type) : ""}}</td>
                        </tr>

                    </tbody>
                </table>
            </template>
     
        </div>
      
    </div>
  </div>
</div>
 </teleport>
</template>

<script>
import { get_rationale_commentary, lowercase, icon_link } from '@/utils';
import ConclusionElement from '@/components/elements/conclusion_element.vue'

export default {
    name: "ModalHTAstatus",
    components: {
        ConclusionElement
    },
    data(){
        return {
            
        }
    },
    props: {         
        item: {
            type: Object
        },
        type: {
            type: String,
            default: ""
        },
    },
    
    methods: {
        lowercase,
        icon_link,        
        get_rationale_commentary,
    },
    computed: {
        link_agency() {
            switch (this.item.agency_model) {
                case "Transparence":
                    return "trs"
            }
            return this.lowercase(this.item.agency_model)
        },
        item_agency() {
            return this.lowercase(this.item.agency_model)
        },
        AgencyObj() {
            return this.$store.getters['result/AgencyObj'];
        },
        listAgency(){
            return this.$store.getters['result/listAgency'];
        }
        
    }
}
</script>
